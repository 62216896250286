@charset "UTF-8";

/* stylesheet customized for SBF by MJ */

/*
* Symbolset
* www.symbolset.com
* Copyright © 2012 Oak Studios LLC
*
* Upload this file to your web server
* and place this within your <head> tags.
* <link href="webfonts/ss-symbolicons-block.css" rel="stylesheet" />
*/

@font-face {
  font-family: "SSSymbolicons";
  src: url('fonts/ss-symbolicons-block/ss-symbolicons-block.eot');
  src: url('fonts/ss-symbolicons-block/ss-symbolicons-block.eot?#iefix') format('embedded-opentype'),
       url('fonts/ss-symbolicons-block/ss-symbolicons-block.woff') format('woff'),
       url('fonts/ss-symbolicons-block/ss-symbolicons-block.ttf')  format('truetype'),
       url('fonts/ss-symbolicons-block/ss-symbolicons-block.svg#SSSymboliconsBlock') format('svg');
  font-weight: normal;
  font-style: normal;
}

/* This triggers a redraw in IE to Fix IE8's :before content rendering. */
html:hover [class^="icon-"]{-ms-zoom: 1;}


[class^="icon-"]:before, [class*=" ss-"]:before,
[class^="icon-"].ss-symbolicons-block:before, [class*=" ss-"].ss-symbolicons-block:before,
[class^="icon-"].right:after, [class*=" ss-"].right:after,
[class^="icon-"].ss-symbolicons-block.right:after, [class*=" ss-"].ss-symbolicons-block.right:after {
  font-family: "SSSymbolicons";
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  text-rendering: optimizeLegibility;
  white-space: nowrap;
  /*-webkit-font-feature-settings: "liga"; Currently broken in Chrome >= v22. Falls back to text-rendering. Safari is unaffected. */
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  -o-font-feature-settings: "liga";
  font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
  display: inline-block;
}

[class^="icon-"].right:before,
[class*=" ss-"].right:before{display:none;content:'';}

/*  Font Awesome styles
    ------------------------------------------------------- */

a [class^="icon-"], a [class*=" icon-"] {
  display: inline-block;
  text-decoration: inherit;
}

.icon-crosshair:before,
.icon-screenshot:before                { content: "⌖"; }

.icon-search:before {content: "🔍";}
 .icon-zoom:before,
 .icon-zoomin:before,
 .icon-zoom-in:before                { content: ""; }

  .icon-zoomout:before,
  .icon-zoom-out:before                { content: ""; }

 .icon-binoculars:before                { content: ""; }
  .icon-raisedhand:before                { content: "✋"; }
  .icon-link:before {content: "🔗";}
  .icon-edit:before,
  .icon-pencil:before {content: "✎";}
  .icon-compose:before                { content: "📝"; }
  .icon-lock:before {content: "🔒";}
  .icon-unlock:before {content: "🔓";}
  .icon-key:before {content: "🔑";}
 .icon-backspace:before                { content: "⌫"; }

 .icon-ban-circle:before,
 .icon-ban:before                { content: "🚫"; }

 .icon-nosmoking:before                { content: "🚭"; }
 .icon-trash:before {content:"";}
 .icon-target:before                { content: "◎"; }
  .icon-stopsign:before                { content: ""; }
  .icon-radioactive:before                { content: "☢"; }
 .icon-skull:before                { content: "💀"; }

 .icon-bolt:before,
 .icon-lightning:before                { content: "☇"; }

 .icon-tag:before {content: "";}
 .icon-newtag:before                { content: ""; }
 .icon-flag:before {content:"⚑";}

  .icon-like:before,
  .icon-thumbs-up:before                { content: "👍"; }

  .icon-dislike:before,
  .icon-thumbs-down:before                { content: "👎"; }

  .icon-heart:before {content:"♥";}
 .icon-addheart:before                { content: ""; }
  .icon-deleteheart:before                { content: "💔"; }
  .icon-star:before { content: "⋆";}
 .icon-piechart:before                { content: ""; }
  .icon-activity:before                { content: "📈"; }
  .icon-flowchart:before                { content: ""; }
 .icon-box:before                { content: "📦"; }
 .icon-crate:before                { content: ""; }
 .icon-home:before {content:"⌂";}
 .icon-fence:before                { content: ""; }
 .icon-buildings:before                { content: "🏢"; }
 .icon-bridge:before                { content: ""; }
  .icon-barn:before                { content: ""; }
  .icon-lodging:before                { content: "🏨"; }
 .icon-earth:before                { content: "🌎"; }
 .icon-globe:before {content:"🌐";}
 .icon-compass:before                { content: ""; }
 .icon-signpost:before                { content: ""; }
  .icon-map:before                { content: ""; }

 .icon-location:before,
 .icon-map-marker:before                { content: ""; }

 .icon-pushpin:before {content:"📌";}
  .icon-script:before                { content: ""; }
  .icon-playscript:before                { content: ""; }
  .icon-stopscript:before                { content: ""; }
  .icon-recordscript:before                { content: ""; }
  .icon-bug:before                { content: "🐛"; }
 .icon-puzzle:before                { content: ""; }
  .icon-window:before                { content: ""; }
  .icon-database:before                { content: ""; }
  .icon-adddatabase:before                { content: ""; }
 .icon-deletedatabase:before                { content: ""; }

  .icon-hdd:before,
  .icon-harddrive:before                { content: ""; }

 .icon-networkhdd:before                { content: ""; }
  .icon-teddy:before                { content: ""; }
 .icon-blocks:before                { content: ""; }
  .icon-rattle:before                { content: ""; }
  .icon-diaper:before                { content: ""; }
  .icon-pailshovel:before                { content: ""; }
  .icon-sweep:before                { content: ""; }
 .icon-headstone:before                { content: "⛼"; }
 .icon-onedie:before                { content: "⚀"; }
  .icon-twodie:before                { content: "⚁"; }
  .icon-threedie:before                { content: "⚂"; }
  .icon-fourdie:before                { content: "⚃"; }
 .icon-fivedie:before                { content: "⚄"; }
 .icon-sixdie:before                { content: "⚅"; }
  .icon-fuzzydice:before                { content: ""; }

 .icon-download:before,
 .icon-downloadcloud:before                { content: ""; }

 .icon-downloadbox:before                { content: ""; }
 .icon-downloadcrate:before                { content: ""; }

 .icon-upload:before,
 .icon-uploadcloud:before                { content: ""; }

 .icon-uploadbox:before                { content: ""; }
 .icon-uploadcrate:before                { content: ""; }
 .icon-transfer:before                { content: "⇆"; }
 .icon-refresh:before {content:"↻";}
  .icon-sync:before                { content: ""; }

  .icon-wifi:before                 { content: ""; }

  .icon-connection:before                { content: ""; }
  .icon-usb:before                { content: ""; }
 .icon-files:before                { content: ""; }
 .icon-addfile:before                { content: ""; }
 .icon-removefile:before                { content: ""; }
  .icon-babybottle:before                { content: "🍼"; }
  .icon-jug:before                { content: ""; }
 .icon-oven:before                { content: ""; }
  .icon-takeout:before                { content: ""; }
 .icon-paperbag:before                { content: ""; }
  .icon-utensils:before                { content: "🍴"; }
  .icon-tshirt:before                { content: "👕"; }
  .icon-graduationcap:before                { content: "🎓"; }
 .icon-fabric:before                { content: ""; }
  .icon-swatch:before                { content: ""; }
  .icon-weave:before                { content: ""; }
 .icon-thread:before                { content: ""; }
  .icon-yarn:before                { content: ""; }
  .icon-crochet:before                { content: ""; }
 .icon-needles:before                { content: ""; }
 .icon-scissorsneedles:before                { content: ""; }
 .icon-button:before                { content: ""; }
  .icon-zipper:before                { content: ""; }
  .icon-sun:before                { content: "☀"; }
 .icon-partlycloudy:before                { content: "⛅"; }
  .icon-rainbow:before                { content: "🌈"; }
  .icon-umbrella:before { content: "☂";}
 .icon-crescentmoon:before                { content: "🌙"; }
  .icon-newmoon:before                { content: "🌑"; }
 .icon-waxingcrescentmoon:before                { content: "🌒"; }
  .icon-waningcrescentmoon:before     { content: "🌘";}

  .icon-adjust:before,
  .icon-firstquartermoon:before                { content: "🌓"; }

  .icon-waxinggibbousmoon:before                { content: "🌔"; }
 .icon-fullmoon:before                { content: "🌕"; }
  .icon-waninggibbousmoon:before                { content: "🌖"; }
 .icon-lastquartermoon:before                { content: "🌗"; }
 .icon-award:before                { content: ""; }
 .icon-medal:before                { content: ""; }
 .icon-badge:before                { content: "📛"; }
 .icon-crown:before                { content: "👑"; }
 .icon-bullseye:before                { content: "🎯"; }
  .icon-eyedropper:before                { content: ""; }
  .icon-crop:before                { content: ""; }
  .icon-layers:before                { content: ""; }

  .icon-magicwand:before,
  .icon-magic:before                { content: ""; }

  .icon-cut:before { content: "✂"}
 .icon-cutpaste:before                { content: ""; }
  .icon-clipboard:before                { content: "📋"; }
 .icon-rules:before                { content: "📏"; }
 .icon-rulerpencil:before                { content: ""; }
 .icon-gridlines:before                { content: ""; }
 .icon-pen:before                { content: "✒"; }
 .icon-pencilbrush:before                { content: ""; }
 .icon-pencilbrushpen:before                { content: ""; }
  .icon-brush:before                { content: ""; }
 .icon-paintroller:before                { content: ""; }
 .icon-fill:before                { content: ""; }
  .icon-stroke:before                { content: ""; }
  .icon-crayons:before                { content: ""; }
 .icon-palette:before                { content: "🎨"; }
 .icon-fliphorizontally:before                { content: ""; }
  .icon-flipvertically:before                { content: ""; }
  .icon-fx:before                { content: ""; }
  .icon-bezier:before                { content: ""; }
  .icon-pixels:before                { content: ""; }
  .icon-downloadhdd:before                { content: ""; }
 .icon-airplay:before                { content: ""; }
 .icon-music:before { content: "♫"}
 .icon-mic:before                { content: "🎤"; }
 .icon-headphones:before {content: "🎧"}
 .icon-volume:before                { content: "🔈"; }
  .icon-radio:before                { content: "📻"; }
 .icon-phonograph:before                { content: ""; }
  .icon-disc:before                { content: "💿"; }
  .icon-discs:before                { content: ""; }
 .icon-playlist:before                { content: ""; }
  .icon-musichome:before                { content: ""; }
 .icon-itunes:before                { content: ""; }
 .icon-camera:before {content: "📷"}

  .icon-image:before,
  .icon-picture:before                { content: "🌄"; }

 .icon-images:before                { content: ""; }
  .icon-searchpicture:before                { content: ""; }
 .icon-video:before                { content: "📹"; }
 .icon-clapboard:before                { content: "🎬"; }
 .icon-film:before {content: ""}
 .icon-playfilm:before                { content: ""; }
  .icon-tv:before                { content: "📺"; }
  .icon-flatscreen:before                { content: ""; }
  .icon-projector:before                { content: ""; }
 .icon-videogame:before                { content: "🎮"; }

 .icon-play:before,
 .icon-play-circle:before {content: "▶"}

 .icon-pause:before {content: ""}
 .icon-stop:before {content: "■"}
 .icon-record:before                { content: "●"; }

  .icon-rewind:before,
  .icon-fast-backward:before,
  .icon-backward:before                { content: "⏪"; }

  .icon-deletefile:before                { content: ""; }
  .icon-searchfile:before                { content: ""; }
  .icon-folder:before                { content: "📁"; }
  .icon-addfolder:before                { content: ""; }
 .icon-removefolder:before                { content: ""; }
  .icon-deletefolder:before                { content: ""; }
  .icon-downloadfolder:before                { content: ""; }
  .icon-uploadfolder:before                { content: ""; }
  .icon-undo:before {content: "↩"}
  .icon-redo:before                { content: "↪"; }
  .icon-quote:before                { content: "“"; }
  .icon-font:before {content: ""}
 .icon-anchor:before                { content: ""; }
   .icon-print:before {content: "⎙"}
  .icon-fax:before                { content: "📠"; }
 .icon-shredder:before                { content: ""; }
  .icon-typewriter:before                { content: ""; }
  .icon-list:before                { content: ""; }
  .icon-action:before                { content: ""; }
  .icon-redirect:before,
  .icon-external-link:before                { content: "↗"; }

  .icon-expand:before,
  .icon-resize-full:before                { content: "⤢"; }

  .icon-contract:before,
  .icon-resize-small:before                { content: ""; }

  .icon-scaleup:before,
  .icon-fullscreen:before                { content: ""; }

 .icon-scaledown:before                { content: ""; }

 .icon-power:before,
 .icon-off:before                { content: ""; }

 .icon-lifepreserver:before                { content: ""; }

 .icon-help:before,
 .icon-question:before,
 .icon-question-sign:before                { content: "❓"; }

  .icon-info-sign:before,
  .icon-info:before                { content: "ℹ"; }

  .icon-alert:before                { content: "⚠"; }
 .icon-caution:before                { content: "⛔"; }

 .icon-plus:before,
 .icon-plus-sign:before {content: "+"}

 .icon-alert:before,
 .icon-warning-sign:before,
 .icon-warning:before                { content: "⚠"; }

 .icon-car:before                { content: "🚘"; }
 .icon-carrepair:before                { content: ""; }
 .icon-taxi:before                { content: "🚖"; }
  .icon-train:before                { content: "🚆"; }
 .icon-bus:before                { content: "🚍"; }
 .icon-truck:before {content: "🚚"}
 .icon-trailer:before                { content: ""; }
 .icon-trailerdump:before                { content: ""; }
 .icon-plane:before {content: "✈"}
 .icon-bike:before                { content: "🚲"; }
  .icon-motorcycle:before                { content: ""; }
  .icon-boat:before                { content: "🚢"; }
  .icon-rocket:before                { content: "🚀"; }
  .icon-ufo:before                { content: ""; }
 .icon-squarekey:before                { content: ""; }
 .icon-tire:before                { content: ""; }
  .icon-fuel:before                { content: "⛽"; }
  .icon-parking:before                { content: ""; }
 .icon-wheelchair:before                { content: "♿"; }
  .icon-restroom:before                { content: "🚻"; }
  .icon-elevator:before                { content: ""; }
  .icon-briefcase:before {content: "💼"}
  .icon-theatre:before                { content: "🎭"; }
 .icon-ticket:before                { content: "🎫"; }
  .icon-golf:before                { content: ""; }
  .icon-billiards:before                { content: "🎱"; }
 .icon-baseball:before                { content: "⚾"; }
  .icon-tennis:before                { content: "🎾"; }
  .icon-basketball:before                { content: "🏀"; }
  .icon-phone:before {content: "📞"}
  .icon-rotaryphone:before                { content: "☎"; }
 .icon-touchtonephone:before                { content: "☏"; }
  .icon-phonebook:before                { content: ""; }
 .icon-voicemail:before                { content: "⌕"; }

 .icon-bullhorn:before,
 .icon-megaphone:before                { content: "📢"; }

 .icon-rss:before { content: ""; }
 .icon-podcast:before                { content: ""; }
 .icon-mailbox:before                { content: "📫"; }
 .icon-send:before                { content: ""; }

  .icon-mail:before,
  .icon-envelope:before,
  .icon-envelope-alt:before                { content: "✉"; }

  .icon-incomingmail:before                { content: "📩"; }
  .icon-inbox:before { content: "📥";}
  .icon-outbox:before                { content: "📤"; }
  .icon-stamp:before                { content: ""; }

 .icon-chat:before,
 .icon-comment:before                { content: "💬"; }

  .icon-comments:before,
  .icon-chats:before                { content: ""; }

 .icon-textchat:before                { content: ""; }

  .icon-exclamationchat:before,
  .icon-exclamation-sign:before                { content: ""; }

 .icon-questionchat:before                { content: ""; }
  .icon-ellipsischat:before                { content: ""; }
  .icon-smile:before                { content: "☻"; }
 .icon-frown:before                { content: "☹"; }
 .icon-toothsmile:before                { content: "😃"; }
  .icon-toothlesssmile:before                { content: "😀"; }
  .icon-user:before                { content: "👤"; }

  .icon-group:before,
  .icon-users:before                { content: "👥"; }

 .icon-adduser:before                { content: ""; }
 .icon-removeuser:before                { content: ""; }
  .icon-deleteuser:before                { content: ""; }
  .icon-fastforward:before,
  .icon-fast-forward:before,
  .icon-forward:before                { content: "⏩"; }

 .icon-skipback:before,
 .icon-step-backward:before                { content: "⏮"; }

  .icon-skip:before,
  .icon-step-forward:before                { content: "⏭"; }

  .icon-eject:before               { content: "⏏";}

  .icon-shuffle:before,
  .icon-random:before                { content: "🔀"; }

 .icon-filecabinet:before                { content: ""; }
 .icon-storagebox:before                { content: ""; }
  .icon-books:before                { content: "📚"; }
 .icon-bookspencil:before                { content: ""; }

 .icon-book:before,
 .icon-openbook:before                { content: "📖"; }

  .icon-bookinsert:before                { content: ''; }
 .icon-notebook:before                { content: "📓"; }
  .icon-ledger:before                { content: "📒"; }
  .icon-album:before                { content: ""; }
 .icon-newspaper:before                { content: "📰"; }
 .icon-spiralbound:before                { content: ""; }
 .icon-notepad:before                { content: ""; }
 .icon-notice:before                { content: ""; }
  .icon-grid:before                { content: ""; }
  .icon-thumbnails:before                { content: ""; }
  .icon-filter:before {content: ""}
  .icon-display:before                { content: "💻"; }
 .icon-laptop:before                { content: ""; }
  .icon-tablet:before                { content: ""; }
  .icon-mobile:before                { content: "📱"; }
  .icon-cellbars:before,
  .icon-signal:before                { content: "📶"; }

  .icon-battery:before                { content: "🔋"; }
 .icon-mediumbattery:before                { content: ""; }
 .icon-emptybattery:before                { content: ""; }
  .icon-chargingbattery:before                { content: ""; }

 .icon-hyphen:before,
 .icon-minus:before,
 .icon-minus-sign:before                { content: "-"; }

 .icon-check:before {content: "✓"}
  .icon-delete:before,
  .icon-remove:before,
  .icon-remove-circle:before,
  .icon-remove-sign:before                { content: "␡"; }

  .icon-dogface:before                { content: "🐶"; }
 .icon-catface:before                { content: "🐱"; }
 .icon-bearface:before                { content: "🐻"; }
  .icon-bird:before                { content: "🐦"; }
  .icon-dog:before                { content: "🐕"; }
 .icon-sheep:before                { content: "🐑"; }
 .icon-pig:before                { content: "🐖"; }
 .icon-bone:before                { content: ""; }
  .icon-tooth:before                { content: ""; }
 .icon-palmtree:before                { content: "🌴"; }
 .icon-leaf:before {content: "🍂"}
  .icon-tree:before                { content: "🌲"; }
  .icon-flower:before                { content: "⚘"; }

  .icon-cog:before,
  .icon-settings:before                { content: "⚙"; }

  .icon-dashboard:before {content: ""}

  .icon-bell:before,
  .icon-notification:before                { content: "🔔"; }

  .icon-toggles:before                { content: ""; }
 .icon-switchon:before                { content: ""; }
  .icon-switchoff:before                { content: ""; }
 .icon-brightness:before                { content: "🔆"; }
  .icon-flashoff:before                { content: ""; }
  .icon-toolbox:before                { content: ""; }
 .icon-tools:before                { content: ""; }
 .icon-wrench:before {content: "🔧"}
 .icon-wrenches:before                { content: ""; }
  .icon-wrenchpencil:before                { content: ""; }
  .icon-football:before                { content: "🏈"; }
  .icon-soccer:before                { content: "⚽"; }
  .icon-flaginhole:before                { content: "⛳"; }
  .icon-paddles:before                { content: ""; }
 .icon-skiboot:before                { content: ""; }
 .icon-skis:before                { content: "🎿"; }
  .icon-hiker:before                { content: ""; }
 .icon-runner:before                { content: "🏃"; }
  .icon-hottub:before                { content: ""; }
  .icon-exercise:before                { content: ""; }
  .icon-hospital:before                { content: '⛨'; }
 .icon-medicalcross:before                { content: ''; }
 .icon-medicalbag:before                { content: ''; }
 .icon-bandage:before                { content: ''; }

  .icon-beaker:before,
  .icon-flask:before                { content: ''; }

  .icon-testtube:before                { content: ''; }

 .icon-up:before,
 .icon-arrow-up:before                { content: '⬆'; }

 .icon-down:before,
 .icon-arrow-down:before { content: '⬇'; }

 .icon-left:before ,
 .icon-arrow-left:before               { content: '⬅'; }

 .icon-right:before,
 .icon-arrow-right:before                { content: '➡'; }

  .icon-navigateup:before,
  .icon-circle-arrow-up:before                { content: ''; }

 .icon-navigateright:before,
 .icon-circle-arrow-right:before                { content: '▻'; }

  .icon-navigatedown:before,
 .icon-circle-arrow-down:before                { content: ''; }

 .icon-navigateleft:before,
 .icon-circle-arrow-left:before                { content: '◅'; }

 .icon-heartuser:before                { content: ""; }
 .icon-males:before                { content: "👬"; }
 .icon-females:before                { content: "👭"; }
 .icon-malefemale:before                { content: "👫"; }
  .icon-userportrait:before                { content: ""; }
  .icon-userframe:before                { content: ""; }
 .icon-usersframe:before                { content: ""; }
  .icon-baby:before                { content: "👶"; }
  .icon-swaddledbaby:before                { content: ""; }
  .icon-robot:before                { content: ""; }
 .icon-happyrobot:before                { content: ""; }
  .icon-alien:before                { content: "👽"; }
 .icon-ghost:before                { content: "👻"; }
 .icon-contacts:before                { content: "📇"; }
  .icon-addressbook:before                { content: "📑"; }
 .icon-footsteps:before                { content: "👣"; }
 .icon-cart:before,
 .icon-shopping-cart:before                { content: ""; }

  .icon-shoppingbag:before                { content: ""; }
  .icon-gift:before {content: "🎁"}
 .icon-store:before                { content: "🏪"; }
 .icon-safe:before                { content: ""; }
  .icon-bill:before                { content: ""; }


  .icon-credit-card:before,
  .icon-creditcard:before                { content: "💳"; }

  .icon-banknote:before,
  .icon-money:before                { content: "💵"; }

  .icon-moneybag:before                { content: "💰"; }
  .icon-calculator:before                { content: ""; }
  .icon-bank:before                { content: "🏦"; }

  .icon-gavel:before,
  .icon-legal:before                { content: "🔨"; }

 .icon-meeting:before                { content: ""; }

 .icon-bar-chart:before,
 .icon-barchart:before                { content: "📊"; }

  .icon-mobiletools:before                { content: ""; }
 .icon-brushmobile:before                { content: ""; }
 .icon-pencilmobile:before        {content: "";}
  .icon-door:before                { content: "🚪"; }
  .icon-washer:before                { content: ""; }
  .icon-dryer:before                { content: ""; }
 .icon-fireplace:before                { content: ""; }
 .icon-toilet:before                { content: "🚽"; }
  .icon-toiletpaper:before                { content: ""; }
 .icon-lightbulb:before                { content: "💡"; }
 .icon-cfl:before                { content: ""; }
 .icon-flashlight:before                { content: "🔦"; }
  .icon-candle:before                { content: ""; }
  .icon-campfire:before                { content: "🔥"; }
  .icon-picnictable:before                { content: ""; }
 .icon-frame:before                { content: ""; }
 .icon-heartframe:before                { content: "💟"; }
  .icon-starframe:before                { content: ""; }
  .icon-trophy:before { content: "🏆"}
 .icon-treeframe:before                { content: ""; }
 .icon-flowerframe:before                { content: ""; }
 .icon-questionframe:before                { content: ""; }
 .icon-utensilsframe:before                { content: ""; }
 .icon-atom:before                { content: "⚛"; }
  .icon-fluxcapacitor:before                { content: ""; }
 .icon-rings:before                { content: "💍"; }
 .icon-balloons:before                { content: "🎈"; }
  .icon-easteregg:before                { content: ""; }
 .icon-jackolantern:before                { content: "🎃"; }
  .icon-menorah:before                { content: ""; }
 .icon-christmastree:before                { content: "🎄"; }
 .icon-screwdriverpencil:before                { content: ""; }
 .icon-hammerscrewdriver:before                { content: ""; }
 .icon-tapemeasure:before                { content: ""; }
 .icon-hourglass:before                { content: "⏳"; }

 .icon-clock:before,
 .icon-time:before                { content: "⏲"; }

 .icon-stopwatch:before                { content: "⏱"; }
 .icon-alarm:before                { content: "⏰"; }
 .icon-calendar:before {content: "📅"}
 .icon-keyboardup:before                { content: ""; }
  .icon-keyboarddown:before                { content: ""; }
  .icon-asterisk:before {content: "✱"}
  .icon-egg:before                { content: ""; }
 .icon-cheese:before                { content: ""; }
  .icon-hotdog:before                { content: ""; }
  .icon-burger:before                { content: "🍔"; }
  .icon-birthdaycake:before                { content: "🎂"; }
  .icon-pancakes:before                { content: ""; }
  .icon-pizza:before                { content: "🍕"; }
 .icon-frenchfries:before                { content: "🍟"; }
 .icon-apple:before                { content: "🍏"; }
 .icon-mug:before                { content: "☕"; }
 .icon-coffee:before                { content: ""; }
  .icon-growler:before                { content: ""; }
 .icon-beer:before                { content: "🍺"; }
  .icon-bottle:before                { content: ""; }
  .icon-wine:before                { content: ""; }

  .icon-glass:before,
  .icon-wineglass:before                { content: "🍷"; }

 .icon-cocktail:before                { content: "🍸"; }
  .icon-soda:before                { content: ""; }
  .icon-cup:before                { content: ""; }
  .icon-share:before {content: ""}






/*
* Symbolset
* www.symbolset.com
* Copyright © 2012 Oak Studios LLC
*
* Upload this file to your web server
* and place this within your <head> tags.
* <link href="webfonts/ss-symbolicons-line.css" rel="stylesheet" />
*/

@font-face {
  font-family: "SSSymbolicons Line";
  src: url('fonts/ss-symbolicons-line/ss-symbolicons-line.eot');
  src: url('fonts/ss-symbolicons-line/ss-symbolicons-line.eot?#iefix') format('embedded-opentype'),
       url('fonts/ss-symbolicons-line/ss-symbolicons-line.woff') format('woff'),
       url('fonts/ss-symbolicons-line/ss-symbolicons-line.ttf')  format('truetype'),
       url('fonts/ss-symbolicons-line/ss-symbolicons-line.svg#SSSymboliconsLine') format('svg');
  font-weight: 300;
  font-style: normal;
}


.outline[class*='icon-']:before {
  font-family: "SSSymbolicons Line";
  font-weight: 300;
}

/*
* Symbolset
* www.symbolset.com
* Copyright © 2012 Oak Studios LLC
*
* Upload this file to your web server
* and place this within your <head> tags.
* <link href="webfonts/ss-social.css" rel="stylesheet" />
*/

@font-face {
  font-family: "SSSocialCircle";
  src: url('fonts/ss-social/ss-social-circle.eot');
  src: url('fonts/ss-social/ss-social-circle.eot?#iefix') format('embedded-opentype'),
       url('fonts/ss-social/ss-social-circle.woff') format('woff'),
       url('fonts/ss-social/ss-social-circle.ttf') format('truetype'),
       url('fonts/ss-social/ss-social-circle.svg#SSSocialCircle') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SSSocial";
  src: url('fonts/ss-social/ss-social-regular.eot');
  src: url('fonts/ss-social/ss-social-regular.eot?#iefix') format('embedded-opentype'),
       url('fonts/ss-social/ss-social-regular.woff') format('woff'),
       url('fonts/ss-social/ss-social-regular.ttf') format('truetype'),
       url('fonts/ss-social/ss-social-regular.svg#SSSocialRegular') format('svg');
  font-weight: normal;
  font-style: normal;
}


.icon-facebook:before,
.icon-twitter:before,
.icon-linkedin:before,
.icon-googleplus:before,
.icon-google-plus:before,
.icon-tumblr:before,
.icon-wordpress:before,
.icon-blogger:before,
.icon-posterous:before,
.icon-youtube:before,
.icon-vimeo:before,
.icon-flickr:before,
.icon-instagram:before,
.icon-pinterest:before,
.icon-svpply:before,
.icon-readmill:before,
.icon-dropbox:before,
.icon-dribbble:before,
.icon-behance:before,
.icon-github:before,
.icon-octocat:before,
.icon-stackoverflow:before,
.icon-paypal:before,
.icon-kickstarter:before,
.icon-foursquare:before,
.icon-skype:before,
.icon-rdio:before,
.icon-spotify:before,
.icon-lastfm:before,
.icon-email:before,
.icon-sociallike:before,
.icon-socialrss:before {
  font-family: SSSocial;
}

.icon-facebook.circle:before,
.icon-twitter.circle:before,
.icon-linkedin.circle:before,
.icon-googleplus.circle:before,
.icon-google-plus.circle:before,
.icon-tumblr.circle:before,
.icon-wordpress.circle:before,
.icon-blogger.circle:before,
.icon-posterous.circle:before,
.icon-youtube.circle:before,
.icon-vimeo.circle:before,
.icon-flickr.circle:before,
.icon-instagram.circle:before,
.icon-pinterest.circle:before,
.icon-svpply.circle:before,
.icon-readmill.circle:before,
.icon-dropbox.circle:before,
.icon-dribbble.circle:before,
.icon-behance.circle:before,
.icon-github.circle:before,
.icon-octocat.circle:before,
.icon-stackoverflow.circle:before,
.icon-paypal.circle:before,
.icon-kickstarter.circle:before,
.icon-foursquare.circle:before,
.icon-skype.circle:before,
.icon-rdio.circle:before,
.icon-spotify.circle:before,
.icon-lastfm.circle:before,
.icon-email.circle:before,
.icon-sociallike.circle:before,
.icon-socialrss:before {
  font-family: SSSocialCircle;
}

.icon-facebook:before      { content: ""; }
.icon-twitter:before      { content: ""; }
.icon-linkedin:before      { content: ""; }

.icon-googleplus:before,
.icon-google-plus:before      { content: ""; }

.icon-tumblr:before      { content: ""; }
.icon-wordpress:before      { content: ""; }
.icon-blogger:before      { content: ""; }
.icon-posterous:before      { content: ""; }
.icon-youtube:before      { content: ""; }
.icon-vimeo:before      { content: ""; }
.icon-flickr:before      { content: ""; }
.icon-instagram:before      { content: ""; }
.icon-pinterest:before      { content: ""; }
.icon-svpply:before      { content: ""; }
.icon-readmill:before      { content: ""; }
.icon-dropbox:before      { content: ""; }
.icon-dribbble:before      { content: ""; }
.icon-behance:before      { content: ""; }
.icon-github:before      { content: ""; }
.icon-octocat:before      { content: ""; }
.icon-stackoverflow:before      { content: ""; }
.icon-paypal:before      { content: ""; }
.icon-kickstarter:before      { content: ""; }
.icon-foursquare:before      { content: ""; }
.icon-skype:before      { content: ""; }
.icon-rdio:before      { content: ""; }
.icon-spotify:before      { content: ""; }
.icon-lastfm:before      { content: ""; }
.icon-email:before      { content: "✉"; }
.icon-sociallike:before      { content: "👍"; }
.icon-socialrss:before      { content: ""; }




